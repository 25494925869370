import '../index.css';
import 'react-toastify/dist/ReactToastify.css';

import { ApolloProvider } from '@apollo/client';
import { css, Global, ThemeProvider } from '@emotion/react';
import styled from '@emotion/styled';
import App, { AppContext, AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';

import * as favicons from '@/assets/favicons';
import safariPinnedTab from '@/assets/safari-pinned-tab.svg?url';
import { CONFIG_SENTRY_ENVIRONMENT, CONFIG_VERSION } from '@/constants/config';
import { MediaContextProvider, MediaStyle, StyledSystemTheme } from '@/constants/styledTheme';
import { COLORS, fontAvenirRoman } from '@/constants/styles';
import createGraphQLClient from '@/graphql/apollo-client';
import * as api from '@/restApi';
import { LoggedInProvider } from '@/utils/auth';
import { IntercomBoot } from '@/utils/intercom';
import { setModalElement } from '@/utils/modal';

const globalStyle = css`
  /* http://meyerweb.com/eric/tools/css/reset/
  v2.0 | 20110126
  License: none (public domain)
  */

  /* prettier-ignore */
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  /* prettier-ignore */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  body {
    line-height: 1;
    font-family: 'Avenir';
  }

  body.overflow-hidden {
    overflow-y: hidden;
  }

  ol,
  ul {
    list-style: none;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  * {
    box-sizing: border-box;
  }

  html {
    height: 100%;
  }

  body {
    height: 100%;
  }

  #root {
    height: 100%;
  }

  p {
    margin: 0;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  b {
    font-family: 'Avenir';
  }

  .Toastify {
    display: flex;
    justify-content: center;

    .Toastify__toast-container {
      display: flex;
      flex-direction: column;
      flex: 1;
      width: inherit;
    }
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  .react-datepicker__input-container input {
    display: block;
    width: 100%;
  }

  html > body > div#hs-eu-cookie-confirmation.hs-cookie-notification-position-bottom {
    box-shadow: none;
    border-top-width: 0;
    z-index: 2147483647 !important;

    &,
    & > div#hs-eu-cookie-confirmation-inner {
      background-color: ${COLORS.slate};
    }

    & > div#hs-eu-cookie-confirmation-inner {
      padding: 30px 40px;

      & > p#hs-eu-cookie-disclaimer,
      & > div#hs-eu-policy-wording > p,
      & > div#hs-eu-policy-wording > p > span {
        ${fontAvenirRoman}
        color: ${COLORS.white};
        font-size: 14px !important;
        line-height: 24px !important;
      }

      & > div#hs-en-cookie-confirmation-buttons-area > div#hs-eu-cookie-confirmation-button-group > a {
        box-sizing: border-box !important;
        font-size: 14px !important;
        line-height: 34px !important;
        padding: 0 20px !important;
        border-color: transparent !important;
        border-style: solid !important;
        border-width: 1px !important;
        border-radius: 20px !important;
        cursor: pointer;
        outline: none;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        transition: all 0.2s ease-in-out;
        transition: background-color 0.1 ease-out;
        white-space: nowrap;
        width: auto;

        &#hs-eu-confirmation-button {
          background-color: ${COLORS.aquaMarine} !important;
          color: ${COLORS.white} !important;
          fill: ${COLORS.white} !important;

          &:hover {
            border-color: transparent !important;
            background-color: ${COLORS.aquarius} !important;
            color: ${COLORS.white} !important;
            fill: ${COLORS.white} !important;
          }
        }

        &#hs-eu-decline-button {
          border-color: ${COLORS.white} !important;
          color: ${COLORS.white} !important;
          fill: ${COLORS.white} !important;

          &:hover {
            border-color: ${COLORS.white} !important;
            background-color: ${COLORS.white} !important;
            color: ${COLORS.fillBlackBlack} !important;
            fill: ${COLORS.fillBlackBlack} !important;
          }
        }
      }
    }
  }

  ${MediaStyle};
`;

const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast {
    &-body {
      text-align: center;
      font-family: 'Avenir-Roman' !important;
      font-size: 14px;
      padding: 0 20px;

      button {
        display: inline;
        margin-left: 20px;
        line-height: 0;
        padding: 13px 18px;
      }
    }

    &--success {
      background-color: ${COLORS.slate};
      border-radius: 5px;
    }

    &.dismissable {
      cursor: default;
      align-items: center;

      .Toastify__toast-body {
        padding: 0 12px;
      }
    }
  }
`;

const [selectedFavicon, faviconColor] = (() => {
  switch (CONFIG_SENTRY_ENVIRONMENT) {
    case 'production':
      return [favicons.production, '#6d64f5'];
    case 'dev':
    default:
      return [favicons.development, '#505A72'];
  }
})();

const toastChunk = <StyledToastContainer closeButton={false} draggable={false} hideProgressBar={true} limit={1} />;

type WindowWithDataLayer = typeof window & { dataLayer?: object[] };

const CustomApp = ({ Component, pageProps }: AppProps) => {
  const client = createGraphQLClient();
  useEffect(() => {
    setModalElement();
    IntercomBoot();

    const windowWithDataLayer = window as WindowWithDataLayer;

    windowWithDataLayer.dataLayer = windowWithDataLayer.dataLayer || [];
    windowWithDataLayer.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  }, []);

  return (
    <LoggedInProvider isLoggedIn={pageProps.isLoggedIn}>
      <ApolloProvider client={client}>
        <ThemeProvider theme={StyledSystemTheme}>
          <MediaContextProvider>
            <Head>
              {/* Generics */}
              <link rel="icon" href={selectedFavicon.favicon} />
              <link rel="icon" type="image/png" href={selectedFavicon.favicon16} sizes="16x16" />
              <link rel="icon" type="image/png" href={selectedFavicon.favicon32} sizes="32x32" />
              <link rel="icon" type="image/png" href={selectedFavicon.favicon96} sizes="96x96" />
              <link rel="icon" type="image/png" href={selectedFavicon.favicon128} sizes="128x128" />
              <link rel="icon" type="image/png" href={selectedFavicon.favicon192} sizes="192x192" />
              <link rel="mask-icon" href={safariPinnedTab} color={faviconColor} />

              {/* Android */}
              <link rel="shortcut icon" type="image/png" href={selectedFavicon.favicon196} sizes="196x196" />

              {/* iOS */}
              <link rel="apple-touch-icon" type="image/png" href={selectedFavicon.favicon120} sizes="120x120" />
              <link rel="apple-touch-icon" type="image/png" href={selectedFavicon.favicon152} sizes="152x152" />
              <link rel="apple-touch-icon" type="image/png" href={selectedFavicon.favicon180} sizes="180x180" />

              {/* Windows 8 IE 10, Windows 8.1 + IE11 and above */}
              <meta name="app-version" content={CONFIG_VERSION} />
              <meta name="msapplication-TileColor" content={faviconColor} />
              <meta name="msapplication-square70x70logo" content={selectedFavicon.favicon128} />
              <meta name="msapplication-square150x150logo" content={selectedFavicon.favicon270} />
              <meta name="msapplication-TileImage" content={selectedFavicon.favicon270} />
              <meta name="msapplication-config" content="none" />
            </Head>
            <Script async={true} src="https://www.googletagmanager.com/gtm.js?id=GTM-WQTC44W" />
            <Global styles={globalStyle} />
            {toastChunk}
            <Component {...pageProps} />
          </MediaContextProvider>
        </ThemeProvider>
      </ApolloProvider>
    </LoggedInProvider>
  );
};

// from:
// https://nextjs.org/docs/api-reference/next.config.js/runtime-configuration
// > A page that relies on publicRuntimeConfig must use getInitialProps or
// > getServerSideProps or your application must have a Custom App with
// > getInitialProps to opt-out of Automatic Static Optimization. Runtime
// > configuration won't be available to any page (or component in a page)
// > without being server-side rendered.
CustomApp.getInitialProps = async (context: AppContext) => {
  const ctx = await App.getInitialProps(context);

  let isLoggedIn = false;

  if (context.ctx.req) {
    // Server side
    const cookie = context.ctx.req.headers.cookie;
    if (cookie) {
      try {
        // TODO: Maybe use loggedIn cookie to skip this if already logged in
        await api.verifyLoggedIn({
          headers: { cookie },
        });
        isLoggedIn = true;
      } catch {
        // Ignore errors and assume we are not logged in
      }
    }
  }

  // NOTE: While this function is also called client side on page transition the isLoggedIn prop is only used to init
  // the LoggedInProvider and is ignored thereafter.

  return { ...ctx, pageProps: { ...ctx.pageProps, isLoggedIn } };
};

export default CustomApp;
