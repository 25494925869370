import { AxiosRequestConfig } from 'axios';

import { LoginSSOValues, LoginValues, RegisterPayload, ResetValues } from '../@types/models';
import { api } from './apiInstance';

export interface ApiSuccess {
  message: string;
  status?: number;
}

export interface ApiError {
  message: string;
  status?: number;
}

interface AxiosOptions {
  verbose?: boolean;
}

const axiosOptions = (aO: AxiosOptions = {}) => ({
  data: { axiosOptions: aO },
});

export async function accountAvailable(name: string, aO?: AxiosOptions): Promise<boolean> {
  const resp = await api().get<{ available: boolean }>(`/validate/accountAvailable/${name}`, axiosOptions(aO));
  return !!resp.data.available;
}

export async function claimEmail(email: string): Promise<string> {
  const resp = await api().post<{ claimId: string }>('/validate/emailClaim', {
    email,
  });
  return resp.data.claimId;
}

export async function confirmPin(claimId: string, pin: string): Promise<boolean> {
  const resp = await api().post<{ confirmed: boolean }>('/validate/emailConfirm', {
    claimId,
    pin,
  });
  return resp.data.confirmed;
}

export async function register(payload: RegisterPayload): Promise<void> {
  await api().post('/register', payload);
  return undefined;
}

export async function login({ id, password, remember }: LoginValues): Promise<void> {
  return api()
    .post('/login', { id, password, remember })
    .then(() => undefined);
}

export async function checkForValidSSODomain({ email }: LoginSSOValues): Promise<boolean> {
  return api()
    .get('/login', { params: { id: email } })
    .then(() => {
      return true;
    })
    .catch(err => {
      // If SSO Domain, we expect a redirect to IDP
      // instead of an API response message
      return !err?.response?.data?.message;
    });
}
export function sendResetPasswordEmail(email: string): Promise<{ data: ApiSuccess }> {
  return api().post('/login/reset/sendEmail', { email });
}

export function resetPassword(payload: ResetValues) {
  return api().post('/login/reset/password', {
    password: payload.password,
    token: payload.token,
  });
}

export async function logout(): Promise<void> {
  try {
    await api().post('/logout');
  } catch {
    undefined;
  }
  return undefined;
}

// export function setCookie(c?: string) {
//   cookie = c;
// }

// export function joinOrgAlreadyAuthenticated(token: string, aO?: AxiosOptions) {
//   return api()
//     .post("/organizations/joinByToken", { token }, axiosOptions(aO))
//     .then((resp) => resp.data);
// }

export async function verifyLoggedIn(config?: AxiosRequestConfig): Promise<void> {
  await api().get('/self', config);
  return undefined;
}
