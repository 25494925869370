var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"10414ab15b90ba6cd3bbfdcf95289b7b90284465"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';

import { CONFIG_SENTRY_ENVIRONMENT } from './src/constants/config';

const SENTRY_DSN = getConfig().publicRuntimeConfig.SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN || 'https://3e344ffbaa734ca6b88133df7aab793b@o296152.ingest.sentry.io/4504097056489472',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.01,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  environment: CONFIG_SENTRY_ENVIRONMENT,
});
